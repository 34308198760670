@import "../../mixins.scss";

.chu-nice {
  .my-gallery-class {
    overflow: hidden;
  }

  .component {
    &.component-carrousel {
      width: 100% !important;
      margin-top: 2rem;
      margin-bottom: 2rem;

      .slick-slide {
        //border-radius: 5px;
      }
    }

    &.component-text {
      color: #000000;
      font-weight: 200;

      @media (max-width: 768px) {
        table {
          border-collapse: collapse;
          width: 100% !important;
          display: block;
          tbody {
            width: 100% !important;
            display: block;
          }
          tr {
            width: 100% !important;
            display: inline-grid;
            grid-template-columns: 50% 50%;

            td {
              padding: 1rem;
              width: 100%;
            }
          }
        }
      }
    }

    &.component-chiffres {
      margin-top: 2rem;
      margin-bottom: 2rem;
      width: 100% !important;
      padding: 0 !important;
      position: relative;

      .slick-track {
        display: flex !important;
      }

      .slick-slide {
        height: inherit !important;
      }

      .prev-slider,
      .next-slider {
        position: absolute;
        z-index: 1;
        top: 50%;
        background: #8bcecb;
        color: white;
        width: 2.2rem;
        height: 2.2rem;
        font-size: 0.9rem;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .prev-slider {
        left: 0;
        transform: translate(-30%, -50%);
      }

      .next-slider {
        right: 0;
        transform: translate(30%, -50%);
      }

      &.has-icon {
        .prev-slider {
          transform: translate(-30%, 20%);
        }

        .next-slider {
          transform: translate(30%, 20%);
        }
      }

      .slick-slide {
        background: transparent;

        .content-chiffre {
          display: flex !important;
          // background-color: #2E6066;
          background: transparent;

          .inner {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border: 1rem solid #fff062;
            padding-top: 1.6rem;
            padding-bottom: 1.6rem;
            padding-left: 1.4rem;
            padding-right: 1.4rem;
            width: 100%;

            &.inner-icon {
              padding-top: 0;
              margin-top: 4rem;
      
              .chiffre {
                margin-top: -2.4rem;
              }
            }
      
            .icon {
              display: flex;
              max-width: 8rem;
              max-height: 8rem;
              min-height: 6rem;
              align-items: center;
              transform: translate(0, -56%);
      
              img {
                object-fit: contain;
                max-width: 8rem;
                max-height: 8rem;
              }
            }

            @media (max-width: 425px) {
              padding-left: 2rem;
              padding-right: 2rem;
            }

          }

          .legende {
            color: #000000;
            width: 100%;
            text-align: center;
            font-size: calc(1.1rem + (26 - 14) * ((100% - 100px) / (1600 - 300)));
            font-weight: 600;
            margin-top: 1rem;
          }

          .chiffre {
            color: #000000;
            line-height: 5rem;
            font-weight: 800;
            font-size: 3rem;
            text-align: center;

            @media (max-width: 1550px) {
              font-size: 3rem;
              line-height: 5rem;
            }

            @media(max-width: 425px){
              font-size: 3rem;
              line-height: 3rem;
            }

          }

          > * { height: auto}

          .inner {
            height: auto;
            box-sizing: content-box;
          }

        }

      }
    }

    .slick-track {
      display: flex;
      align-items: center;
    }

    &.component-carrousel {
      padding: 0 !important;
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
      position: relative;
      //height: 235px;
      //height: 335px;

      .slick-slider {
        //height: 235px;
        //height: 335px;
        .slick-slide {
          //height: 235px;
          //height: 335px;
        }
        .component-image {
          //height: 235px;
          //height: 335px;
          img {
            height: 335px;
            border-radius: 5px;
          }
        }
      }

      .prev-slider,
      .next-slider {
        position: absolute;
        z-index: 1;
        top: 50%;
        background: #8bcecb;
        color: white;
        width: 2.2rem;
        height: 2.2rem;
        font-size: 0.9rem;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .prev-slider {
        left: 0;
        transform: translate(-50%, -120%);
      }

      .next-slider {
        right: 0;
        transform: translate(50%, -120%);
      }
    }

    &.component-image {
      width: 100% !important;
      padding: 0 !important;
    }

    &.component-breve {
      color: #000000;
      margin-top: 1.5rem;

      &.style {
        .inner {
          padding-left: 0.8rem !important;
          border-left: solid 10px;
        }

        @media (max-width: 425px) {
          //border: 3px solid;
          padding-top: 1.2rem;
          padding-bottom: 1.2rem;
        }
      }

      > .inner {
        > h2 {
          margin-top: 0;
        }
      }
    }

    &.component-encadre {
      border: 1rem solid #fff062;
      margin-top: 1.5rem;
      padding: 1.2rem 2.4rem;


      &.dark {
        q, p, h2, h3, figcaption, .author, .chiffre, .legende {
          color: #ffffff !important;
        }
      }

      .component-testimonial {
        border: none;
      }
    }
  }

  @import "./components-media.scss";
}
