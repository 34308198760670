@import url("https://use.typekit.net/ofs3zeb.css");

@import "../../mixins.scss";

.chu-nice {
  letter-spacing: 0.3px !important;

  .couverture {
    height: 100vh;
    width: 100%;
    background-color: #fff;
    //background-image: linear-gradient(47deg, #000000 0%, #fff062 100%);

    .couvertureInner {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      overflow: hidden;
      border-left: 1px solid rgba(0,0,0,0.28);

      @media(max-width: 425px) {
        border-left: none;
      }

      .couvertureInfos {
        //transform: translateX(-1.4rem);
        left: 0;
        margin-left: 6rem;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-start;
        //max-width: 350px;
        max-width: 400px;
        position: absolute;
        z-index: 1;

        @media (max-width: 1024px) {
          align-items: center;
          text-align: center;
        }

        @media(max-width: 1024px){
          position: absolute;
          left: 0;
          margin-left: 2rem;
          transform: translateX(0);
          padding-right: 2rem;
          width: 100%;
          display: flex;
          justify-content: center;
          max-width: initial;
          //align-items: center;
        }

        @media(max-width: 425px){
          align-items: stretch;
          text-align: left;
        }

        a.start-btn {
          display: flex;
          padding: 1rem 1.6rem;
          margin-top: 1rem;
          color: white;
          position: relative;
          font-size: 20px;
          line-height: 24px;

          background-color: #f08162;
          transform: skew(0, -1.8deg);

          > div {
            transform: skew(0, 1.8deg);
          }

          @media(max-width: 425px) {
            margin: 0 auto;
            padding: 1.1rem 1.5rem;
            margin-top: 2rem;
            width: 73vw;
            div {
              width: 100%;
              text-align: center;
            }
          }

          span {
            color: white;
            font-weight: bold;
            @media(max-width: 425px) {
              padding-left: 0;
            }
          }

          &:hover {
            &:after {
              transform: translate(0px, 5px) scaleX(0);
              transform-origin: left;
              transition: all 0.4s ease;
            }
          }
        }

        .subtitle {
          font-family: 'urw-din-condensed';
          font-size: 28px;
          color: #0080c8;
          margin-bottom: 18px;
          overflow: visible;

          @media(max-width: 1024px) {
            br {
              display: none;
            }
            text-align: center;
            margin-bottom: 2rem;
          }
        }

        h1 {
          font-family: 'urw-din-condensed';
          font-weight: bold;
          margin-top: 2rem;
          margin-bottom: 0.7rem;
          font-size: 53px;
          color: #0080c8;
          line-height: 46px;

          @media(max-width: 1180px) {
            font-size: 2.5rem;
            line-height: 3rem;
          }

          @media(max-width: 1024px) {
            font-size: 2rem;
            line-height: 2.4rem;
            padding-left: 1rem;
            text-align: center;

            br {
              display: none;
            }
          }
        }
        span {
          color: black;
          font-size: 1rem;
          line-height: 1.3rem;
          @media(max-width: 1024px) {
            padding-left: 1rem;
            font-size: 1.1rem;
          }
        }
      }

      .couvertureImage {
        position: absolute;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        width: 100%;
        background-color: #fff;

        .imageOuter {
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            will-change: opacity;
            opacity: 0;

            &.loaded {
              opacity: 1;
              transition: opacity 0.3s ease 0.300s;
            }
          }
        }
      }
    }

  }
}

@import "./couverture-media.scss";
