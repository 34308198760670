//@media (max-width: 768px){
@media (min-height: 900px) and (max-width: 1025px){

  .chu-nice {
    .numero {

    }
  }

}

@media (min-height: 425px) {

  .chu-nice {
    .numero {
      min-height: initial;
    }
  }

}
