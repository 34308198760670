@font-face {
  font-family: 'icomoon-chu-nice';
  src:  url('icomoon-chu-nice.eot?47cxjj');
  src:  url('icomoon-chu-nice.eot?47cxjj#iefix') format('embedded-opentype'),
  url('icomoon-chu-nice.ttf?47cxjj') format('truetype'),
  url('icomoon-chu-nice.woff?47cxjj') format('woff'),
  url('icomoon-chu-nice.svg?47cxjj#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ico-chu-nice-"], [class*=" ico-chu-nice-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon-chu-nice' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ico-chu-nice-close:before {
  content: "\e91b";
}
.ico-chu-nice-arrow-chu-nice:before {
  content: "\e900";
}
.ico-chu-nice-arrow-left-rounded:before {
  content: "\e901";
}
.ico-chu-nice-arrow-left:before {
  content: "\e902";
}
.ico-chu-nice-arrow-right-rounded:before {
  content: "\e903";
}
.ico-chu-nice-arrow-right:before {
  content: "\e904";
}
.ico-chu-nice-byridmi .path1:before {
  content: "\e905";
  color: rgb(0, 0, 0);
}
.ico-chu-nice-byridmi .path2:before {
  content: "\e906";
  margin-left: -3.529296875em;
  color: rgb(0, 0, 0);
  opacity: 0.45;
}
.ico-chu-nice-byridmi .path3:before {
  content: "\e907";
  margin-left: -3.529296875em;
  color: rgb(0, 0, 0);
  opacity: 0.45;
}
.ico-chu-nice-chu-nice .path1:before {
  content: "\e908";
  color: rgb(0, 0, 0);
}
.ico-chu-nice-chu-nice .path2:before {
  content: "\e909";
  margin-left: -6.63671875em;
  color: rgb(0, 0, 0);
  opacity: 0.6;
}
.ico-chu-nice-facebook:before {
  content: "\e90a";
}
.ico-chu-nice-instagram:before {
  content: "\e90b";
}
.ico-chu-nice-kiosque:before {
  content: "\e90c";
}
.ico-chu-nice-menu:before {
  content: "\e90d";
}
.ico-chu-nice-next-article:before {
  content: "\e90e";
}
.ico-chu-nice-prev-article:before {
  content: "\e90f";
}
.ico-chu-nice-progress-dot:before {
  content: "\e910";
}
.ico-chu-nice-progress:before {
  content: "\e911";
}
.ico-chu-nice-read-circle:before {
  content: "\e912";
}
.ico-chu-nice-read-public:before {
  content: "\e913";
}
.ico-chu-nice-read:before {
  content: "\e914";
}
.ico-chu-nice-ridmi .path1:before {
  content: "\e915";
  color: rgb(0, 0, 0);
}
.ico-chu-nice-ridmi .path2:before {
  content: "\e916";
  margin-left: -3em;
  color: rgb(0, 0, 0);
  opacity: 0.45;
}
.ico-chu-nice-search:before {
  content: "\e917";
}
.ico-chu-nice-time:before {
  content: "\e918";
}
.ico-chu-nice-triangle-intro:before {
  content: "\e919";
}
.ico-chu-nice-twitter:before {
  content: "\e91a";
}
.ico-chu-nice-quote1:before {
  content: "\e91f";
}
.ico-chu-nice-quote2:before {
  content: "\e920";
}