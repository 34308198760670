body.chu-nice {

  #rgpd-portal {
    z-index: 1;
  }

  .magazine {
    z-index: 0;
  }

  .rgpd-outer {
    //width: 100%;
    //height: 100%;
    //background-color: rgba(33, 41, 52, 0.85) !important;
    //display: flex!important;
    //bottom: 0;

    &.hide {
      opacity: 0;
      //transition: opacity 0.4s ease;
    }

    &.show {
      opacity: 1;
      //transition: opacity 0.6s ease;
    }

    .rgpd-inner {
      //background: #fff;
      //border-radius: 2px;
      //box-shadow: 0 1px 3px rgba(33,41,52,.75);
      //display: flex;
      //flex-direction: column;
      ////width: 100%;
      //margin: auto;
      ////max-width: 755px;
      ////min-width: 320px!important;
      //position: relative;
      //padding: 1.5rem;
      //padding-bottom: 2rem;
      z-index: 0;

      @keyframes openOpacity {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }

      @media (max-width: 425px) {

        &:before {
          z-index: 1000000;
          //100000
          content: "";
          width: 100%;
          height: 100%;
          position: fixed;
          top: 0;
          left: 0;
          background: rgba(#fff062, 0.5);
          animation: openOpacity 0.4s ease;
        }
      }

      @media(max-width: 768px) {
        /*width: 94%;
        margin: auto;
        overflow: auto;
        max-width: 770px;
        min-width: auto;
        max-height: 89vh;*/
      }

      @keyframes opacityEntryRGPD {
        0% {
          opacity: 0;
          transform: translateY(30px);
        }
        100%{
          opacity: 1;
          transform: translateY(0px);
        }
      }

      .header {
        .title {
          display: flex;
          font-size: 1.2rem;
          letter-spacing: 0;
          align-items: center;
          color: #000000;
          font-weight: bold;
          //text-transform: uppercase;

          @media (max-width: 768px) {
            font-size: 2.7rem;
            letter-spacing: 0;
            margin-bottom: 0.4rem;
          }

          img {
            margin-right: 0.4rem;
            transform: scale(0.92) translateY(-2px);
            width: 30px;
            height: 30px;
          }
        }
      }

      .rgpd-message {
        bottom: 1.3rem;
        left: 1.3rem;
        //width: 305px;
        //width: 305px;
        width: 340px;
        position: fixed;
        z-index: 100000000000000000;
        background: white;
        box-shadow: 0 2px 20px 0 rgba(0,0,0,0.15);
        opacity: 0;
        animation: opacityEntryRGPD 0.4s ease-out forwards;

        .main {
          width: 100%;
        }

        @media (max-width: 768px) {
          width: 367px;
        }

        @media (max-width: 425px) {
          width: calc(100% - 2.6rem);
        }

        .inner {
          //padding: 1rem;
          //padding: 1.8rem;
          //padding: 1.6rem;
          //padding: 1.3rem;
          padding: 1.7rem;
        }

        .main {
          padding: 0;
          &:after {
            content: none;
          }
        }

      }

      .main {
        text-align: center;

        &:after {
          content: none;
        }

        p {
          color: black;
        }
      }

      .btn {
        color: white;
        background-color: #ed6607;
        font-size: 0.8rem;
        line-height: 1.3rem;
        //letter-spacing: 1px;
        padding: 0.5rem 1.15rem;
        margin-right: 0.8em;
        margin-left: 0;
        cursor: pointer;
        outline: none;
        border: 0;
        border-radius: 2px;
        font-weight: 600;
        transition: all 0.1s ease-out;

        @media (max-width: 768px) {
          color: white;
          background-color: #fff062;
          font-size: 1.2rem;
          line-height: 2.5rem;
          padding: 0.5rem 2rem;
        }

        @at-root body:not(.touch) #{&} {
          &:hover {
            //background-color:  #ce052c;
            color: darken(#fff062, 10%);
          }
        }

        &.reject {
          margin-bottom: 0;
          color: #ACACAC;
          text-decoration: none;
          background: transparent;
          font-weight: 400;
          padding-left: 0.4rem;

          &:hover {
            @at-root body:not(.touch) #{&} {
              background: transparent;
              text-decoration: underline;
            }
          }
        }

      }

      .accept-btns {
        display: flex;
        //@media(max-width: 768px){
        //    display: flex;
        //    flex-direction: column;
        //}
      }

      .rgpd-settings {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(33, 41, 52, 0.85) !important;
        display: flex;
        z-index: 10000000000000;
        align-items: center;
        justify-content: center;
        overflow: auto;

        .inner {
          background: #fff;
          border-radius: 2px;
          box-shadow: 0 1px 3px rgba(33,41,52,.75);
          display: flex;
          flex-direction: column;
          width: 100%;
          margin: auto;
          //max-width: 583px;
          max-width: 600px;
          min-width: 320px!important;
          position: relative;
          padding: 3rem;
          opacity: 0;
          animation: opacityEntryRGPD 0.4s ease-out forwards;

          @media (max-width: 768px){
            padding: 2.7rem;
          }

          .main {
            p {
              font-size: 0.7rem;
              line-height: 1.2rem;
              text-align: left;

              @media(max-width: 768px) {
                font-size: 1.1rem;
                line-height: 1.9rem;
                text-align: left;
                margin-bottom: 1.4rem;
              }

            }
          }

          .header {

            .title {
              display: flex;
              font-size: 1.3rem;
              margin-bottom: 0.9rem;

            @media(max-width: 768px){
                margin-bottom: 1rem;
              }

              span {
                line-height: 2.2rem;
              }
              img {
                margin-right: 0.9rem;
                transform: scale(1.2);

                @media(max-width: 768px){
                  margin-right: 1.3rem;
                }
              }
            }
          }

        }

        .accept-btns {
          margin-top: 1rem;
        }

        .footer {
          display: flex;
          flex-direction: row;
          align-items: center;
          //justify-content: space-between;
          justify-content: flex-start;
          padding-left: 0;
          padding-right: 0;

          .btn {
            margin-right: 0;
          }

        }

        .main {
          margin: 0;
          //margin-left: 1rem;
          //margin-top: 1rem;
          margin-top: 0rem;
          margin-bottom: 0rem;
          //margin-right: 1rem;
          padding-top: 0;
          //border: solid 1px #d9d9d9;
          padding-right: 0;
          padding-left: 0;
          padding-top: 0;

          &:after {
            content: none;
          }

          //p {
          //  @media (max-width: 768px) {
          //    line-height: 1.4rem;
          //  }
          //}

          .service .title {
            //padding-left: 1rem;
            font-weight: bold;
            font-size: 0.8rem;
            text-align: left;
            @media(max-width: 768px){
              font-size: 1.1rem;
            }
          }

          .service {
            padding-top: 1rem;
            padding-bottom: 1rem;
            //border-bottom: solid 1px #d9d9d9;
            display: flex;
            justify-content: space-between;
            padding-right: 1rem;

            .activate {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              @media (max-width: 768px) {
                justify-content: flex-start;
                align-items: center;
                margin-left: 0.6rem;
                transform: scale(0.8);
                transform-origin: top right;
              }

              > span > span {
                //transform: scale(1.3);
                //box-shadow: 0 2px 5px 0 rgba(0,0,0,0.15);
              }

              span.value {
                font-size: 0.8rem;
                margin-top: 0.4rem;
              }
            }

            .desc {
              font-size: 0.8rem;
              //padding-left: 1rem;
              text-align: left;
              margin-top: 0.4rem;
              color: rgba(0, 0, 0, 0.35);
              @media(max-width: 425px){
                font-size: 1.05rem;
                line-height: 1.8rem;
              }
            }

          }

          .category-rgpd {
            border: solid 1px #E1E1E1;
            border-radius: 3px;
            padding: 1rem;
            margin-bottom: 1.3rem;
            padding-bottom: 0;
            //&:last-child {
            //  border:0;
            //}
          }

          .category-rgpd > .title {
            padding-bottom: 0.6rem;
            border-bottom: solid 1px #d9d9d9;
            text-align: left;
            margin-top: 0;
            font-size: 0.8rem;
            margin-bottom: 0;
            line-height: 0.9rem;
            font-weight: 500;
            color: black;
          }
        }

      }

      .header {
        text-align: left;
        font-size: 2.3rem;
        line-height: 3rem;
        //font-weight: bold;
        color: black;
      }

      .footer {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: flex-start;
        padding-top: 0.4rem;

        .more, .back {
          margin-bottom: 0;
          color: #ACACAC;
          text-decoration: none;
          background: white;
          font-weight: 400;
          padding: 0;
          margin-left: 1.2rem;

          @at-root body:not(.touch) #{&} {
            &:hover {
              text-decoration: underline;
            }
          }

          @media(max-width: 768px) {
            //margin-top: 1rem;
          }
        }

      }

      .main {
        margin: 0;
        padding-left: 5rem;
        padding-right: 5rem;
        //padding-top: 2rem;
        padding-top: 1rem;

        @media(max-width: 768px) {
          margin: 0;
          padding-left: 2rem;
          padding-right: 2rem;
          padding-top: 1rem;
        }

        p {
          font-size: 0.72rem;
          line-height: 1.2rem;
          text-align: left;
          margin-top: 0.1rem;
          margin-bottom: 1rem;

          @media (max-width: 768px) {
            line-height: 1.8rem;
            font-size: 1rem;
          }

          a, button {
            color: #fff062;
            text-decoration: underline !important;
            outline: none;
            padding: 0;
            margin: 0;
            @at-root body:not(.touch) #{&} {
              &:hover {
                color: darken(#fff062, 10%);
              }
            }
          }

        }

      }


    }

  }


}
